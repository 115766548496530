import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import ApiHandler from "../../../Handler/ApiHandler";
import {Grid, IconButton, Slide, Tooltip} from "@mui/material";
import {initFormHandler} from '../../../Handler/FormHandler';
import {cacheTablePage, cacheTableServer} from '../../../Cache';
import {TableReload} from '../../../Action/TableAction';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import TitleComponent from '../../../Component/TitleComponent';
import {dispatch} from '../../../App';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import SelectComponent from '../../../Component/SelectComponent';
import moment from "moment";
import {Article} from "@mui/icons-material";
import { getRoutePathname } from '../../../Handler/RouteHandler';
import { Link } from 'react-router-dom';

function ClientServiceOrderClientLitigeList() {
    const [loadingContent, setLoadingContent] = React.useState(true);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [optionsChannel, setOptionsChannel] = React.useState([]);
    const [optionsOrderClientState, setOptionsOrderClientState] = React.useState([]);
    const [optionsOrderClientLitigeState, setOptionsOrderClientLitigeState] = React.useState([]);
    const [optionsOrderClientLitigeType, setOptionsOrderClientLitigeType] = React.useState([]);

    const columns = [
        {
            name: "number",
            label: "Référence",
            options: {filter: true, sort: true}
        },
        {
            name: "ref",
            label: "Ref litige",
            options: {filter: true, sort: true}
        },
        {
            name: "clientEmail",
            label: "Email",
            options: {filter: true, sort: true}
        },
        {
            name: "clientName",
            label: "Client",
            options: {filter: true, sort: true}
        },
        {
            name: "channel",
            label: "Canal",
            options: {filter: true, sort: true}
        },
        {
            name: "orderClientState",
            label: "Etat",
            options: {filter: true, sort: true}
        },
        {
            name: "orderClientLitigeType",
            label: "Type de litige",
            options: {filter: true, sort: true}
        },
        {
            name: "orderClientLitigeState",
            label: "Etat du litige",
            options: {filter: true, sort: true}
        },
        {
            name: "reason",
            label: "Raison du litige",
            options: {filter: true, sort: true}
        },
        {
            name: "author",
            label: "Auteur du litige",
            options: {filter: true, sort: true}
        },
        {
            name: "createdAt",
            label: "Date de la commande",
            options: {filter: true, sort: true}
        },
        {
            name: "litigeAt",
            label: "Date de litige",
            options: {filter: true, sort: true}
        },
        {
            name: "dealAt",
            label: "Date du cloture",
            options: {filter: true, sort: true}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ];
    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Visualiser'} placement="left">
                    <Link to={getRoutePathname('client_service_order_client_litige', {uuid: row.orderClientLitigeUuid})}>
                        <IconButton><Article style={{color: '#17a2b8'}}/></IconButton>
                    </Link>
                </Tooltip>
            </>
        );
    };

    // FormSearch
    const [formSearch, setFormSearch] = React.useState({
        number: {
            name: 'number',
            label: 'Référence',
            textHelper: 'Rechercher par référence.',
            type: 'text',
            defaultValue: cacheTableServer['api_client_service_order_client_litige_list'] && cacheTableServer['api_client_service_order_client_litige_list'].number ? cacheTableServer['api_client_service_order_client_litige_list'].number : '',
            options: {}
        },
        ref: {
            name: 'ref',
            label: 'Ref litige',
            textHelper: 'Rechercher par ref litige.',
            type: 'text',
            defaultValue: cacheTableServer['api_client_service_order_client_litige_list'] && cacheTableServer['api_client_service_order_client_litige_list'].ref ? cacheTableServer['api_client_service_order_client_litige_list'].ref : '',
            options: {}
        },
        clientEmail: {
            name: 'clientEmail',
            label: 'Email',
            textHelper: 'Rechercher par email.',
            type: 'text',
            defaultValue: cacheTableServer['api_client_service_order_client_litige_list'] && cacheTableServer['api_client_service_order_client_litige_list'].clientEmail ? cacheTableServer['api_client_service_order_client_litige_list'].clientEmail : '',
            options: {}
        },
        clientName: {
            name: 'clientName',
            label: 'Client',
            textHelper: 'Rechercher par client.',
            type: 'text',
            defaultValue: cacheTableServer['api_client_service_order_client_litige_list'] && cacheTableServer['api_client_service_order_client_litige_list'].clientName ? cacheTableServer['api_client_service_order_client_litige_list'].clientName : '',
            options: {}
        },
        channel: {
            name: 'channel',
            label: 'Canal',
            textHelper: 'Rechercher par canaux.',
            type: 'integer',
            defaultValue: cacheTableServer['api_client_service_order_client_litige_list'] && cacheTableServer['api_client_service_order_client_litige_list'].channel ? cacheTableServer['api_client_service_order_client_litige_list'].channel : 0,
            options: {}
        },
        orderClientState: {
            name: 'orderClientState',
            label: 'État',
            textHelper: 'Rechercher par état.',
            type: 'integer',
            defaultValue: cacheTableServer['api_client_service_order_client_litige_list'] && cacheTableServer['api_client_service_order_client_litige_list'].orderClientState ? cacheTableServer['api_client_service_order_client_litige_list'].orderClientState : 0,
            options: {}
        },
        orderClientLitigeState: {
            name: 'orderClientLitigeState',
            label: 'État litige',
            textHelper: 'Rechercher par état de litige.',
            type: 'integer',
            defaultValue: cacheTableServer['api_client_service_order_client_litige_list'] && cacheTableServer['api_client_service_order_client_litige_list'].orderClientLitigeState ? cacheTableServer['api_client_service_order_client_litige_list'].orderClientLitigeState : 0,
            options: {}
        },
        orderClientLitigeType: {
            name: 'orderClientLitigeType',
            label: 'Type litige',
            textHelper: 'Rechercher par type de litige.',
            type: 'integer',
            defaultValue: cacheTableServer['api_client_service_order_client_litige_list'] && cacheTableServer['api_client_service_order_client_litige_list'].orderClientLitigeType ? cacheTableServer['api_client_service_order_client_litige_list'].orderClientLitigeType : 0,
            options: {}
        }
    });
    const handlerFormSearch = initFormHandler(formSearch, setFormSearch);
    const inputSearchChange = (index, value) => {
        handlerFormSearch.setValue(index, value);
        cacheTablePage['api_client_service_order_client_litige_list'] = 0;
        cacheTableServer['api_client_service_order_client_litige_list'].page = 0;
        cacheTableServer['api_client_service_order_client_litige_list'][index] = value;
        dispatch(TableReload('api_client_service_order_client_litige_list'))
    };

    React.useEffect(() => {
        handlerFormSearch.start();

        ApiHandler.get({route: 'api_select_channel'}, (response) => {
            let data = [{value: 0, label: 'Tous'}, ...response.data];
            setOptionsChannel(data);
        });
        ApiHandler.get({route: 'api_select_order_client_state'}, (response) => {
            let data = [{value: 0, label: 'Tous'}, ...response.data];
            setOptionsOrderClientState(data);
        });
        ApiHandler.get({route: 'api_select_order_client_litige_state'}, (response) => {
            let data = [{value: 0, label: 'Tous'}, ...response.data];
            setOptionsOrderClientLitigeState(data);
        });
        ApiHandler.get({route: 'api_select_order_client_litige_type'}, (response) => {
            let data = [{value: 0, label: 'Tous'}, ...response.data];
            setOptionsOrderClientLitigeType(data);
        });

        setBreadcrumbs({
            title: 'Liste des commandes client en litige',
            context: 'Service client',
            description: '',
            links: []
        });

        setLoadingContent(false);
    }, []);

    return (
        <ContentViewComponent loading={loadingContent} breadcrumbs={breadcrumbs}>
            <Slide direction={'left'} in={true} {...{timeout: 750}}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ShadowBoxComponent>
                        <TitleComponent title={'Filtres'}/>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                                <TextFieldComponent id={'number'} handler={handlerFormSearch} onChange={(val) => inputSearchChange('number', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                                <TextFieldComponent id={'ref'} handler={handlerFormSearch} onChange={(val) => inputSearchChange('ref', val)}/>
                            </Grid>
                            <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                                <TextFieldComponent id={'clientEmail'} handler={handlerFormSearch} onChange={(val) => inputSearchChange('clientEmail', val)}/>
                            </Grid>
                            <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                                <TextFieldComponent id={'clientName'} handler={handlerFormSearch} onChange={(val) => inputSearchChange('clientName', val)}/>
                            </Grid>
                            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                                <SelectComponent id={'channel'} options={optionsChannel} handler={handlerFormSearch} onChange={(val) => inputSearchChange('channel', val)}/>
                            </Grid>
                            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                                <SelectComponent id={'orderClientState'} options={optionsOrderClientState} handler={handlerFormSearch} onChange={(val) => inputSearchChange('orderClientState', val)}/>
                            </Grid>
                            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                                <SelectComponent id={'orderClientLitigeState'} options={optionsOrderClientLitigeState} handler={handlerFormSearch} onChange={(val) => inputSearchChange('orderClientLitigeState', val)}/>
                            </Grid>
                            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                                <SelectComponent id={'orderClientLitigeType'} options={optionsOrderClientLitigeType} handler={handlerFormSearch} onChange={(val) => inputSearchChange('orderClientLitigeType', val)}/>
                            </Grid>
                        </Grid>
                    </ShadowBoxComponent>
                </Grid>
            </Slide>

            <br/>

            <TableComponent
                id={'api_client_service_order_client_litige_list'}
                title={'Commandes client en litige'}
                columns={columns}
                promiseServerData={(resolve, options) => {
                    let datas = handlerFormSearch.getData();
                    let filters = {
                        limit: options.rowsPerPage,
                        offset: options.page * options.rowsPerPage,
                        sortName: options.sortOrder.name,
                        sortDirection: options.sortOrder.direction,
                        number: formSearch.number.value ? formSearch.number.value : '',
                        ref: formSearch.ref.value ? formSearch.ref.value : '',
                        clientEmail: formSearch.clientEmail.value ? formSearch.clientEmail.value : '',
                        clientName: formSearch.clientName.value ? formSearch.clientName.value : '',
                        channel: (datas.channel === 0 || !datas.channel) ? '' : datas.channel,
                        orderClientState: (datas.orderClientState === 0 || !datas.orderClientState) ? '' : datas.orderClientState,
                        orderClientLitigeState: (datas.orderClientLitigeState === 0 || !datas.orderClientLitigeState) ? '' : datas.orderClientLitigeState,
                        orderClientLitigeType: (datas.orderClientLitigeType === 0 || !datas.orderClientLitigeType) ? '' : datas.orderClientLitigeType,
                    };

                    ApiHandler.get({
                            route: 'api_client_service_order_client_litige_list',
                            data: filters
                        },
                        (response) => {
                            let data = response.data ? response.data.data : [];
                            for (let index in data) {
                                data[index].action = getAction(data[index]);
                                data[index].createdAt = moment(data[index].createdAt).format('lll');
                                data[index].dealAt = data[index].dealAt ? moment(data[index].dealAt).format('lll') : '-';
                                data[index].litigeAt = moment(data[index].litigeAt).format('lll');
                                data[index].number = data[index].number ?? '-';
                                data[index].ref = data[index].ref ?? '-';
                                data[index].clientEmail = data[index].clientEmail ?? '-';
                                data[index].clientName = data[index].clientName ?? '-';
                                data[index].channel = data[index].channel ?? '-';
                                data[index].orderClientState = data[index].orderClientState ?? '-';
                                data[index].orderClientLitigeState = data[index].orderClientLitigeState ?? '-';
                                data[index].orderClientLitigeType = data[index].orderClientLitigeType ?? '-';
                            }
                            resolve(data, (response.data ? response.data.count : 0));
                        });
                }}
                search={false}
            />
            <br/><br/>
        </ContentViewComponent>
    );
}

export default ClientServiceOrderClientLitigeList;
